div.modal-mass-actions-component {
  div.modal-content {
    div.modal-header {
      gap: 10px;
      user-select: none;
      box-shadow: 0px 0px 12px -8px;
      z-index: 1;
      
      div.modal-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      button.btn-close {
        margin-top: -15px;
        transition: 0.3s;
      }

      div.modal-mass-actions-header-actions {
        display: flex;
        gap: 6px;

        svg {
          cursor: pointer;
          color: #707070;
          transition: 0.3s;
        }

        svg:hover {
          color: #606060;
        }
      }
    }

    div.modal-body {
      user-select: none;

      div.modal-mass-actions-content {
        display: flex;
        flex-direction: column;
        gap: 16px;

        span.modal-mass-actions-message {
          font-size: 17px;
  
          span {
            font-weight: 600;
          }
        }

        div.modal-mass-actions-content-section {
          display: flex;
          gap: 10px;
          min-height: 160px;

          div.modal-mass-actions-content-options {
            display: flex;
            flex-direction: column;
            gap: 5px;
            // flex-wrap: wrap;
            min-width: 170px;
            max-width: 170px;
  
            span.modal-mass-actions-content-options-item {
              display: flex;
              align-items: center;
              justify-content: center;
              // flex: 1 1 calc(50% - 10px);
              border: 1px solid #4a69bd;
              color: #4a69bd;
              background-color: #fff;
              border-radius: 5px;
              padding: 5px;
              transition: 0.3s;
            }
  
            span.modal-mass-actions-content-options-item-selected,
            span.modal-mass-actions-content-options-item:not(.modal-mass-actions-content-options-item-disabled):hover {
              cursor: pointer;
              color: #fff;
              background-color: #3b57a5;
            }

            span.modal-mass-actions-content-options-item-disabled {
              cursor: default !important;
              opacity: 0.4;
            }
          }

          div.modal-mass-actions-content-options-info {
            display: flex;
            flex-direction: column;
            padding: 10px;
            background-color: #f5f5f5;
            border-radius: 5px;
            width: 100%;
            max-width: calc(100% - 170px - 10px); // 170px = max-width de options e 10px do gap de section

            span.modal-mass-actions-content-options-info-message {
              display: flex;
              flex-direction: column;
              gap: 10px;

              span {
                font-size: 15px;
              }

              span:nth-child(1) {
                font-weight: 600;
                color: #3b57a5;
              }
            }

            span.modal-mass-actions-content-options-info-message-max-limit {
              color: #3b57a5;
              text-align: center;

              span {
                font-weight: 600;
              }
            }

            div.modal-mass-actions-content-options-info-form {
              display: flex;
              flex-direction: column;
              gap: 10px;

              span.modal-mass-actions-content-options-info-form-message {
                font-size: 15px;
                font-weight: 600;
                color: #3b57a5;
              }
            }
          }

          div.modal-mass-actions-content-options-info-empty {
            justify-content: center;
            align-items: center;

            span.modal-mass-actions-content-options-info-message {
              font-size: 14px;
              font-weight: 600;
              color: #3b57a5;
              text-align: center;
            }
          }
        }


      }
      
    }

    div.modal-footer {
      border-top: unset;
      justify-content: space-between;
      box-shadow: 0px -3px 17px -13px #0000006e;
      z-index: 1;

      button {
        border-radius: 5px;
        padding: 5px 10px;
        transition: 0.3s;
        cursor: pointer;
        min-width: 100px;
      }

      button.modal-mass-actions-btn-submit {
        background-color: #4a69bd;
        color: #fff;
        border: none;
      }

      button.modal-mass-actions-btn-submit:not(:disabled):hover {
        background-color: #4a69bd;
      }

      button.modal-mass-actions-btn-submit:disabled {
        opacity: 0.4;
        cursor: default;
      }

      button.modal-mass-actions-btn-cancel {
        background-color: #fff;
        color: #4a69bd;
        border: 1px solid #4a69bd;
      }

      button.modal-mass-actions-btn-cancel:hover {
        background-color: #e8e8e8;
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  div.modal-mass-actions-component {
    // max-width: unset !important;

    div.modal-content {
      div.modal-body {
        div.modal-mass-actions-content {
          div.modal-mass-actions-content-section {
            flex-direction: column;

            div.modal-mass-actions-content-options {
              max-width: unset !important;
            }

            div.modal-mass-actions-content-options-info {
              max-width: unset !important;
            }
          }
        }
      }
    }
  }
}